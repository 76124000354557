export const useSmartSticky = (getCurrentAmplitude = () => 0, getOffset = () => 0) => {
  const transform = ref(0)

  if (process.client) {
    // const previousWindowScroll = ref(window.scrollY)
    const previousWindowScroll = ref(0)

    const computeTransform = () => {
      transform.value -= window.scrollY - Math.max(previousWindowScroll.value, getOffset())
      transform.value = Math.min(
        0, // fully visible
        Math.max(
          getCurrentAmplitude() * -1, // fully hidden
          Math.max(transform.value, -window.scrollY), // IOS - Top of the page bug fix
        ),
      )
    }

    const handleScroll = () => {
      if (window.scrollY > getOffset()) {
        requestAnimationFrame(computeTransform)
      }
      requestAnimationFrame(() => {
        previousWindowScroll.value = window.scrollY
      })

      if (window.scrollY === 0) {
        transform.value = 0
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll, true)
      handleScroll()
    })
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll, true)
    })
  }

  return { transform }
}
